<template>
  <button
    class="button"
    :disabled="disabled"
    :class="{
      small: props.small,
      inactive: props.inactive,
      red: props.danger,
      transparent: props.transparent,
      empty: props.empty,
      grey: props.grey,
      dark: props.dark,
      animation: props.animation,
    }"
    @click.prevent="buttonClicked()"
  >
    <slot></slot>
  </button>
</template>

<script setup>
const props = defineProps({
  small: {
    type: Boolean,
    required: false,
  },
  inactive: {
    type: Boolean,
    default: false,
    required: false,
  },
  danger: {
    type: Boolean,
    required: false,
  },
  transparent: {
    type: Boolean,
    required: false,
  },
  grey: {
    type: Boolean,
    required: false,
  },
  dark: {
    type: Boolean,
    required: false,
  },
  empty: {
    type: Boolean,
    required: false,
  },
  animation: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const $emit = defineEmits(['click'])
const buttonClicked = () => {
  $emit('click')
}
</script>

<style lang="scss" scoped>
.button {
  border-radius: 8px;
  padding: 14px 44px;
  border: none;
  gap: 10px;
  background: linear-gradient(84.9deg, #c7d939 0%, #69b795 50.5%, #29abe2 100%);
  cursor: pointer;
  // @include flex-row-items-center;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    filter: brightness(50%);
  }

  &.small {
    @extend .button;
    font-weight: 400;
    font-size: 14px;
    padding: 7px 13px;
  }

  &.inactive {
    @extend .button;
    background: transparent;
    border: 1px solid white;
  }

  &.red {
    @extend .button;
    background: #e5463b;
  }

  &.transparent {
    @extend .button;
    background: transparent;
    color: #151515;
    border: 1px solid white;
  }
  &.empty {
    @extend .button;
    background: transparent;
  }

  &.grey {
    @extend .button;
    background: rgba(255, 255, 255, 0.1) !important;
    // border: 1px solid rgba(255, 255, 255, 0.1) !important;
    cursor: default;
  }

  &.dark {
    @extend .button;
    background: linear-gradient(90deg, #29abe2 0%, #69b795 50%, #c7d939 100%);
    opacity: 50%;
    border: 1px solid #5c5d5f !important;
    cursor: default;
  }

  &.animation {
    @extend .button;
    z-index: 1;
    will-change: transform;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      width: 100%;
      z-index: -1;
      background: linear-gradient(
        84.9deg,
        #c7d939 0%,
        #69b795 50.5%,
        #29abe2 100%
      );
      background-size: 100%;
      border-radius: 32px;
      animation: pulsing 1.5s infinite cubic-bezier(0.5, 0, 0, 0.5);
      filter: blur(0px);
    }
  }

  @keyframes pulsing {
    to {
      filter: blur(15px);
    }
  }
}

@media (max-width: 750px) {
  .button {
    padding: 8px 19px;
    font-size: 14px;
  }

  .animation {
    &:before {
      animation: none !important;
    }
  }
}
</style>
